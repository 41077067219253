<template>
  <div>
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab v-for="(item, index) in items" :key="index">
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(content, i) in items" :key="i">
        <component v-bind:is="content.content"> </component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ComertialReport: () =>
      import("@/components/reports-kpis/ComertialReportV2.vue"),
    PlarformCampaignReport: () =>
      import("@/components/reports-kpis/PlatformCampaignReportV2.vue"),
    AnualMktReportVersion2: () =>
      import("@/components/reports-kpis/AnualMktReportVersion2.vue")
  },
  data() {
    return {
      role: localStorage.getItem("user_role"),
      tab: null,
      items: [
        // {
        //   tab: "Comercial",
        //   content: "ComertialReport"
        // },
        {
          tab: "Comercial",
          content: "ComertialReport"
        },
        {
          tab: "Campañas/Plataforma",
          content: "PlarformCampaignReport"
        },
        //Se deshabilita temporalmente el reporte anual hasta corregirlo.
        {
          tab: "Anual MKT",
          content: "AnualMktReportVersion2"
        }
      ],
      isDialogActive: false,
      userRole: localStorage.getItem("user_role") || ""
    };
  },
  async mounted() {
    if (this.role) {
      if (this.role === "marketing" || this.role === "marketingv2") {
        this.items = [this.items[1]];
      }
      if (this.role === "developer") {
        this.items = [this.items[0], this.items[1]];
      }
    }
    await this.fetchfilters();
  },
  methods: {
    ...mapActions({
      fetchfilters: "leads/fetchGetLeadsFilters"
    })
  },
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  }
};
</script>

<style></style>
